import { useTheme } from '@mui/material'

type Props = {
  mode?: 'light' | 'dark'
  href?: string
  width?: number
  height?: number
  style?: React.CSSProperties
}

const Logo: React.FC<Props> = ({
  mode = 'dark',
  href,
  width = 118,
  height = 36,
  style,
}) => {
  const theme = useTheme()
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 135 42"
      fill="none"
    >
      <path
        d="M5.36526 31.6998C5.36526 31.9079 5.30383 32.0624 5.16909 32.1978C5.03237 32.3332 4.86129 32.3966 4.63078 32.3966H0V38.4699H6.0568C7.97424 38.4699 9.50528 37.9045 10.6063 36.7896C11.7067 35.676 12.2635 34.1654 12.2635 32.3008V10.5234H5.36526V31.6998Z"
        fill={
          mode === 'dark'
            ? theme.palette.typoColor.main
            : theme.palette.white.main
        }
      />
      <path
        d="M30.3933 11.4757C28.7546 10.545 26.8787 10.0728 24.8173 10.0728C22.7565 10.0728 20.8754 10.5444 19.2235 11.475C17.5729 12.405 16.2506 13.6817 15.2929 15.2683C14.3332 16.8601 13.8457 18.6897 13.8457 20.7068C13.8457 22.6989 14.3325 24.5219 15.2922 26.1249C16.2493 27.726 17.5736 29.0034 19.2268 29.9215C20.8774 30.8383 22.7585 31.3033 24.818 31.3033C26.8523 31.3033 28.7209 30.8383 30.3708 29.9215C32.0234 29.0041 33.3477 27.7267 34.3061 26.1249C35.2658 24.5206 35.7526 22.6976 35.7526 20.7068C35.7526 18.6903 35.2658 16.8614 34.3048 15.2683C33.3464 13.6817 32.0313 12.4056 30.3933 11.4757ZM22.6938 16.8086C23.2968 16.4129 23.9917 16.2214 24.8173 16.2214C25.6409 16.2214 26.3285 16.4123 26.9184 16.8059C27.5108 17.2002 27.9785 17.7359 28.31 18.3984C28.6456 19.0695 28.816 19.8337 28.816 20.6698C28.816 21.5311 28.6462 22.3079 28.31 22.9796C27.9745 23.65 27.5194 24.1712 26.9184 24.5721C26.3279 24.9651 25.6409 25.156 24.8173 25.156C23.9923 25.156 23.2968 24.9644 22.6938 24.5701C22.0901 24.1745 21.6172 23.6395 21.2869 22.9796C20.9514 22.3086 20.781 21.5311 20.781 20.6698C20.781 19.8337 20.9507 19.0695 21.2869 18.3984C21.6172 17.7379 22.0901 17.2029 22.6938 16.8086Z"
        fill={
          mode === 'dark'
            ? theme.palette.typoColor.main
            : theme.palette.white.main
        }
      />
      <path
        d="M54.2465 11.3894C52.7921 10.5162 51.0827 10.0737 49.1652 10.0737C48.3423 10.0737 47.5523 10.182 46.8191 10.396C46.0781 10.6113 45.3944 10.933 44.7874 11.3518C44.5497 11.5156 44.3258 11.6926 44.117 11.8808V2.38281H37.2188V20.3326C37.2188 22.5143 37.6699 24.4568 38.5596 26.1054C39.4559 27.7666 40.7405 29.0644 42.3773 29.9627C44.0021 30.8524 45.9195 31.3042 48.0774 31.3042C49.7637 31.3042 51.2888 31.0394 52.6104 30.5156C53.9407 29.9885 55.0913 29.2197 56.0312 28.2269C56.9658 27.2388 57.691 26.0605 58.1897 24.7236C58.6857 23.3914 58.9367 21.9257 58.9367 20.3696C58.9367 18.3372 58.5173 16.5334 57.689 15.0096C56.8588 13.4786 55.6996 12.2606 54.2465 11.3894ZM44.2293 20.7071C44.2293 19.8405 44.3885 19.0598 44.7042 18.3848C45.014 17.7203 45.4479 17.2104 46.0305 16.8253C46.6012 16.4495 47.2894 16.2586 48.0767 16.2586C48.8733 16.2586 49.5358 16.4436 50.1005 16.8227C50.6666 17.2045 51.1157 17.7322 51.4347 18.3921C51.7597 19.0664 51.9248 19.8445 51.9248 20.7071C51.9248 21.5711 51.7597 22.3577 51.4341 23.0446C51.1098 23.725 50.6738 24.2428 50.1005 24.6285C49.5365 25.0077 48.874 25.1926 48.0767 25.1926C47.2881 25.1926 46.5999 25.0024 46.0305 24.6259C45.4486 24.2421 45.0153 23.7276 44.7055 23.0512C44.3898 22.3637 44.2293 21.575 44.2293 20.7071Z"
        fill={
          mode === 'dark'
            ? theme.palette.typoColor.main
            : theme.palette.white.main
        }
      />
      <path
        d="M67.7172 10.5234H60.7812V30.8537H67.7172V10.5234Z"
        fill={
          mode === 'dark'
            ? theme.palette.typoColor.main
            : theme.palette.white.main
        }
      />
      <path
        d="M100.339 24.5482C99.5607 23.7563 98.6228 23.354 97.5508 23.354C96.4788 23.354 95.5415 23.7563 94.7641 24.5482C93.9887 25.3382 93.5957 26.2794 93.5957 27.3474C93.5957 28.4181 93.99 29.3553 94.7674 30.1314C95.5442 30.9095 96.4808 31.3031 97.5508 31.3031C98.6215 31.3031 99.5587 30.9088 100.335 30.1314C101.113 29.3547 101.507 28.4181 101.507 27.3474C101.507 26.28 101.114 25.3382 100.339 24.5482ZM99.5693 29.3659C98.9946 29.9412 98.3341 30.2206 97.5508 30.2206C96.7681 30.2206 96.1082 29.9412 95.533 29.3659C94.9577 28.7913 94.6783 28.1308 94.6783 27.3474C94.6783 26.5634 94.959 25.8956 95.5363 25.3071C96.1102 24.7219 96.7694 24.4379 97.5501 24.4379C98.3315 24.4379 98.9907 24.7219 99.5647 25.3071C100.142 25.895 100.423 26.5634 100.423 27.3474C100.424 28.1308 100.145 28.7913 99.5693 29.3659Z"
        fill={
          mode === 'dark'
            ? theme.palette.typoColor.main
            : theme.palette.white.main
        }
      />
      <path
        d="M133.83 30.8537H126.895V10.5234H133.83V30.8537Z"
        fill={
          mode === 'dark'
            ? theme.palette.typoColor.main
            : theme.palette.white.main
        }
      />
      <path
        d="M123.131 15.3323C122.274 13.7108 121.031 12.4149 119.437 11.4796C117.846 10.5457 115.904 10.0728 113.666 10.0728C111.453 10.0728 109.5 10.5516 107.862 11.4961C106.218 12.4426 104.932 13.7432 104.038 15.3607C103.146 16.9737 102.694 18.8224 102.694 20.8574C102.694 22.8086 103.079 24.586 103.838 26.1408C104.601 27.7101 105.686 28.9737 107.06 29.8984C108.446 30.8317 110.062 31.3039 111.865 31.3039C113.003 31.3039 114.009 31.1401 114.854 30.8185C115.709 30.4922 116.447 30.0292 117.046 29.4426C117.252 29.2412 117.445 29.0298 117.626 28.8086V30.8535H124.412V20.7451C124.413 18.765 123.982 16.944 123.131 15.3323ZM116.925 23.0358C116.614 23.7227 116.182 24.2432 115.601 24.6263C115.023 25.008 114.365 25.193 113.592 25.193C112.791 25.193 112.114 25.0067 111.522 24.623C110.93 24.2399 110.492 23.7207 110.184 23.0351C109.867 22.3363 109.706 21.541 109.706 20.6698C109.706 19.827 109.865 19.0582 110.181 18.3852C110.49 17.7227 110.929 17.2134 111.523 16.829C112.115 16.4453 112.792 16.259 113.593 16.259C114.365 16.259 115.023 16.4446 115.601 16.8257C116.184 17.2108 116.618 17.7214 116.928 18.3852C117.243 19.0615 117.403 19.8303 117.403 20.6698C117.402 21.5377 117.241 22.3337 116.925 23.0358Z"
        fill={
          mode === 'dark'
            ? theme.palette.typoColor.main
            : theme.palette.white.main
        }
      />
      <path
        d="M11.5478 1.19419C10.7697 0.402246 9.83177 0 8.75977 0C7.68778 0 6.75053 0.402246 5.97312 1.19419C5.19769 1.98415 4.80469 2.92536 4.80469 3.9934C4.80469 5.06341 5.19901 6 5.97642 6.77741C6.75317 7.55548 7.68976 7.94914 8.75977 7.94914C9.83045 7.94914 10.7677 7.55482 11.5438 6.77741C12.3219 5.99934 12.7155 5.06275 12.7155 3.9934C12.7162 2.92536 12.3232 1.98415 11.5478 1.19419ZM10.7783 6.01189C10.2036 6.58719 9.54313 6.86658 8.75977 6.86658C7.97708 6.86658 7.31724 6.58719 6.74194 6.01189C6.16664 5.43659 5.88725 4.77609 5.88725 3.9934C5.88725 3.20938 6.16796 2.54161 6.74524 1.9531C7.31922 1.3679 7.9784 1.08388 8.75911 1.08388C9.54049 1.08388 10.1997 1.3679 10.7736 1.9531C11.3509 2.54095 11.6316 3.20938 11.6316 3.9934C11.633 4.77609 11.3536 5.43593 10.7783 6.01189Z"
        fill={
          mode === 'dark'
            ? theme.palette.typoColor.main
            : theme.palette.white.main
        }
      />
      <path
        d="M67.036 1.23179C66.258 0.439845 65.32 0.0375977 64.248 0.0375977C63.1761 0.0375977 62.2388 0.439845 61.4614 1.23179C60.686 2.02175 60.293 2.96296 60.293 4.03099C60.293 5.10167 60.6873 6.03892 61.4647 6.81501C62.2414 7.59308 63.178 7.98674 64.248 7.98674C65.3187 7.98674 66.256 7.59242 67.0321 6.81501C67.8101 6.03826 68.2038 5.10167 68.2038 4.03099C68.2045 2.96296 67.8121 2.02175 67.036 1.23179ZM66.2665 6.04883C65.6919 6.62412 65.0314 6.90352 64.248 6.90352C63.4654 6.90352 62.8055 6.62412 62.2302 6.04883C61.6549 5.47419 61.3755 4.81369 61.3755 4.03033C61.3755 3.24632 61.6562 2.57855 62.2335 1.99004C62.8075 1.40484 63.4667 1.12082 64.2474 1.12082C65.0288 1.12082 65.6879 1.40484 66.2619 1.99004C66.8392 2.57789 67.1199 3.24632 67.1199 4.03033C67.1212 4.81435 66.8425 5.47419 66.2665 6.04883Z"
        fill={
          mode === 'dark'
            ? theme.palette.typoColor.main
            : theme.palette.white.main
        }
      />
      <path
        d="M133.152 1.23179C132.374 0.439845 131.436 0.0375977 130.364 0.0375977C129.292 0.0375977 128.355 0.439845 127.577 1.23179C126.801 2.02175 126.408 2.96296 126.408 4.03099C126.408 5.10167 126.803 6.03892 127.58 6.81501C128.357 7.59308 129.293 7.98674 130.363 7.98674C131.434 7.98674 132.371 7.59242 133.147 6.81501C133.925 6.03826 134.319 5.10167 134.319 4.03099C134.32 2.96296 133.927 2.02175 133.152 1.23179ZM132.382 6.04883C131.808 6.62412 131.147 6.90352 130.364 6.90352C129.581 6.90352 128.921 6.62412 128.346 6.04883C127.771 5.47419 127.491 4.81369 127.491 4.03033C127.491 3.24632 127.772 2.57855 128.349 1.99004C128.923 1.40484 129.583 1.12082 130.363 1.12082C131.145 1.12082 131.804 1.40484 132.378 1.99004C132.955 2.57789 133.236 3.24632 133.236 4.03033C133.237 4.81435 132.958 5.47419 132.382 6.04883Z"
        fill={
          mode === 'dark'
            ? theme.palette.typoColor.main
            : theme.palette.white.main
        }
      />
      <path
        d="M77.1454 34.5846C76.3673 33.7926 75.4294 33.3904 74.3574 33.3904C73.2854 33.3904 72.3482 33.7926 71.5708 34.5846C70.7953 35.3745 70.4023 36.3157 70.4023 37.3838C70.4023 38.4545 70.7967 39.3917 71.5741 40.1678C72.3508 40.9459 73.2874 41.3395 74.3574 41.3395C75.4281 41.3395 76.3654 40.9452 77.1414 40.1678C77.9195 39.391 78.3132 38.4545 78.3132 37.3838C78.3138 36.3157 77.9208 35.3745 77.1454 34.5846Z"
        fill={
          mode === 'dark'
            ? theme.palette.typoColor.main
            : theme.palette.white.main
        }
      />
      <path
        d="M90.4276 34.5846C89.6496 33.7926 88.7117 33.3904 87.6397 33.3904C86.5677 33.3904 85.6304 33.7926 84.853 34.5846C84.0776 35.3745 83.6846 36.3157 83.6846 37.3838C83.6846 38.4545 84.0789 39.3917 84.8563 40.1678C85.6331 40.9459 86.5696 41.3395 87.6397 41.3395C88.7103 41.3395 89.6476 40.9452 90.4237 40.1678C91.2017 39.391 91.5954 38.4545 91.5954 37.3838C91.5961 36.3157 91.2031 35.3745 90.4276 34.5846Z"
        fill={
          mode === 'dark'
            ? theme.palette.typoColor.main
            : theme.palette.white.main
        }
      />
      <path
        d="M89.8907 14.6418C89.0941 13.26 87.9316 12.1438 86.4369 11.3241C84.9495 10.5084 83.1384 10.0942 81.0552 10.0942C78.9983 10.0942 77.1886 10.5077 75.6767 11.3221C74.1582 12.1411 72.9706 13.2561 72.1483 14.6359C71.3259 16.015 70.9092 17.5724 70.9092 19.2646V30.8743H77.8075V19.4146C77.8075 18.83 77.9435 18.3115 78.2229 17.8287C78.4997 17.3492 78.8834 16.9602 79.3643 16.6715C79.8464 16.3822 80.3874 16.2422 81.0182 16.2422C81.6238 16.2422 82.1522 16.3822 82.6344 16.6709C83.1205 16.9628 83.4944 17.3419 83.7764 17.8287C84.0558 18.3122 84.1912 18.8314 84.1912 19.4146V30.8743H91.0895V19.2646C91.0888 17.5757 90.6853 16.0203 89.8907 14.6418ZM76.8709 29.9014H71.8458V26.0903H76.8709V29.9014ZM90.1509 29.9014H85.1258V26.0903H90.1509V29.9014Z"
        fill={
          mode === 'dark'
            ? theme.palette.typoColor.main
            : theme.palette.white.main
        }
      />
    </svg>
  )
}

export default Logo
