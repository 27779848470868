import type { InputBaseProps } from '@mui/material/InputBase'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@mui/material/Typography'
import { StyledInputBase } from './style'

interface MyInputProps extends InputBaseProps {
  type: string
  containerProps?: any
  label?: string
}

const Input: React.FC<MyInputProps> = ({
  type,
  id,
  label,
  startAdornment,
  containerProps,
  ...props
}) => {
  return (
    <Box className="form-group mui-input" {...containerProps}>
      {label && (
        <InputLabel htmlFor={id}>
          <Typography variant="body_m_medium">{label}</Typography>
        </InputLabel>
      )}
      <StyledInputBase
        type={type}
        id={id}
        {...props}
        inputProps={{
          ...props.inputProps,
          style: {
            paddingLeft: startAdornment ? '50px' : '16px',
          },
        }}
        startAdornment={startAdornment}
      />
    </Box>
  )
}

export default Input
