import React, { useState } from 'react'
import EyeOpenIcon from '../../Icons/EyeOpenIcon'
import EyeCloseIcon from '../../Icons/EyeCloseIcon'
import { Typography } from '@mui/material'
import { INPUT_MAX_WIDTH } from '../../../styles/muiTheme/GlobalStyles'

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  containerProps?: React.HTMLAttributes<HTMLDivElement>
}

const PasswordInput: React.FC<Props> = props => {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <div
      className="form-group"
      style={{
        position: 'relative',
        maxWidth: INPUT_MAX_WIDTH,
      }}
      {...props.containerProps}
    >
      <Typography
        component={'label'}
        variant="body_m_medium"
        htmlFor={props.id}
      >
        {props.label}
      </Typography>
      <input
        id={props.id}
        type={showPassword ? 'text' : 'password'}
        minLength={8}
        style={{
          maxWidth: INPUT_MAX_WIDTH,
        }}
        {...props}
      />
      <span
        className={`show-hide-btn ${showPassword && 'hide'}`}
        onClick={() => {
          setShowPassword(!showPassword)
        }}
      >
        {showPassword ? <EyeOpenIcon /> : <EyeCloseIcon />}
      </span>
    </div>
  )
}

export default PasswordInput
