import * as Sentry from '@sentry/nextjs'

export interface ILog {
  title?: string
  error?: Error
  user?: string
  extra?: {
    [key: string]: string
  }
}

/**
 * The function `LogError` captures and logs an error with additional information using the Sentry
 * error tracking service.
 * @param {ILog}  - - `title`: A string representing the title or name of the error or exception.
 */
export const LogError = ({ title, error, user, extra }: ILog) => {
  Sentry.withScope(scope => {
    if (title) scope.setTransactionName(title)
    if (user) scope.setUser({ email: user })
    if (extra) scope.setExtras(extra)

    Sentry.captureException(error)
  })
}
