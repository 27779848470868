import { useRouter } from 'next/router'
import React, { PropsWithChildren } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import {
  StyledContentContainer,
  StyledFeedbackBanner,
  StyledImageContainer,
} from './style'

const AuthLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter()

  const { redirectFromForgot } = router.query
  return (
    <>
      {redirectFromForgot && (
        <StyledFeedbackBanner>
          We’ve sent an email to your email address. Follow the steps provided
          in the email to update your password.
        </StyledFeedbackBanner>
      )}
      <Grid container bgcolor={'white.main'}>
        <Grid
          xs={12}
          lg={6}
          component={'aside'}
          sx={{
            display: { xs: 'none', lg: 'block' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              padding: 5,
              bgcolor: theme => theme.palette.backgroundInput.main,
            }}
          >
            <StyledImageContainer />
          </Box>
        </Grid>
        <Grid xs={12} lg={6} display="flex" alignItems="center">
          {/* @ts-ignore */}
          <StyledContentContainer component={'main'}>
            {children}
          </StyledContentContainer>
        </Grid>
      </Grid>
    </>
  )
}

export default AuthLayout
