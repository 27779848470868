import { Box, Card, styled } from '@mui/material'
import { INPUT_MAX_WIDTH } from '../../../styles/muiTheme/GlobalStyles'

export const StyledFeedbackBanner = styled('div')(() => ({
  backgroundColor: '#000',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '51px',
  zIndex: 9999,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  fontSize: '1.125rem',
  fontWeight: 500,
  lineHeight: '1.1875rem',
}))

export const StyledImageContainer = styled(Box)(({ theme }) => ({
  display: 'none',
  backgroundImage: `url('/images/resource/login.webp')`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  width: '100%',
  maxWidth: '662px',
  margin: '0 auto',
  minHeight: 'calc(100vh - 80px)',
  [theme.breakpoints.up('lg')]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}))

export const StyledContentContainer = styled(Card, {
  shouldForwardProp: prop => prop !== 'component',
})<{ component: string }>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  minHeight: '100vh',
  justifyContent: 'center',
  width: '100%',
  maxWidth: INPUT_MAX_WIDTH,
  margin: '0 auto',
  padding: '0 2rem',
  boxShadow: 'none',
  [theme.breakpoints.up('md')]: {
    // marginLeft: 'clamp(1rem, 8vw, 145px)',
    // justifyContent: 'flex-start',
  },
}))
